<template>
  <div
    :id="`${modalId}`"
    class="modal fade"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    aria-hidden="true"
  >
    <div
      class="modal-dialog sx-modal-responsive"
      :style="{
        width: String.format('{0}%', calendarInfo.lightBoxRatio),
        'min-width': String.format('{0}%', calendarInfo.lightBoxRatio),
      }"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" v-if="designedItems.length">
            <i class="far fa-calendar-alt"></i>&nbsp;
            <template v-if="isNewEvent">
              {{
                this.$t(
                  "Set.NewFormat",
                  {},
                  { locale: this.$store.state.activeLang }
                ).replaceAll("OBJECT_NAME", this.pageData.name)
              }}
            </template>
            <template v-else>
              {{
                String.format(
                  "{0} {1}",
                  pageData.name,
                  $t("Set.Edit", {}, { locale: this.$store.state.activeLang })
                )
              }}
            </template>
          </h5>
          <h5 v-else>
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body" :class="{ 'p-0': designedItems.length }">
          <SetLoader
            :isDocumentTitleChange="false"
            v-if="!designedItems.length"
          />
          <form class="form w-100" :id="formId">
            <div class="container-fluid">
              <div class="row">
                <div class="col">
                  <AlertBox type="warning" :messages="formError" />
                  <ValidationMessageList
                    v-if="crudResponseValidationModel.showValidations"
                    :isModal="false"
                    :msg="crudResponseValidationModel.msg"
                    :message="crudResponseValidationModel.message"
                    :errors="crudResponseValidationModel.errors"
                    :warnings="crudResponseValidationModel.warnings"
                    :informations="crudResponseValidationModel.informations"
                  />
                  <ValidationMessageListInModal
                    :errors="crudResponseValidationModel.modalErrors"
                    :warnings="crudResponseValidationModel.modalWarnings"
                    :informations="
                      crudResponseValidationModel.modalInformations
                    "
                    :businessRuleErrors="
                      crudResponseValidationModel.businessRuleErrors
                    "
                  />
                </div>
              </div>
            </div>
            <HiddenFields crudType="Calendar" :pageData="pageData" />
            <DesignItem
              v-for="element in designedItems"
              :key="element.id"
              :element="element"
              :items="designedItems"
            />
          </form>
        </div>
        <div class="modal-footer">
          <template v-if="designedItems.length">
            <template v-if="isNewEvent">
              <button
                @click="saveEvent('btn-calendar-event-save')"
                type="button"
                class="btn btn-success btn-calendar-event-save me-2"
              >
                <span>
                  <i class="fa fa-save"></i>
                  {{
                    $t(
                      "BaseModelFields.Save",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</span
                >
              </button>
            </template>
            <template v-else>
              <button
                @click="updateEvent('btn-calendar-event-edit')"
                type="button"
                class="btn btn-warning btn-calendar-event-edit me-2"
              >
                <span>
                  <i class="fa fa-edit"></i>
                  {{
                    $t("Set.Edit", {}, { locale: this.$store.state.activeLang })
                  }}</span
                >
              </button>
            </template>
          </template>
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
            <i class="fa fa-times"></i>
            {{
              $t(
                "BaseModelFields.Close",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { createToast } from "mosha-vue-toastify";
import { showModal, hideModal } from "@/core/helpers/dom";

import HiddenFields from "@/components/set-design/form-items/HiddenFields.vue";
import DesignItem from "@/components/set-design/DesignItem.vue";
import ValidationMessageList from "@/components/custom/validation-message/List.vue";
import ValidationMessageListInModal from "@/components/custom/validation-message/Modal.vue";
export default {
  name: "EventModal",
  props: ["pageData", "designedItems", "crudType", "calendarInfo"],
  data() {
    return {
      modalId: "fullCalendarEventModal",
      model: {},
      formError: [],
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "BaseModelFields.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses:
        ".btn-calendar-event-save, .btn-calendar-event-edit",
      formId: "",
      isNewEvent: false,
      crudResponseValidationModel: {},
    };
  },
  watch: {
    crudType: {
      immediate: true,
      handler() {
        this.formId = this.crudType === "new" ? "newForm" : "editForm";
        this.isNewEvent = this.crudType === "new";
      },
    },
    pageData: {
      immediate: true,
      handler() {
        this.formError = [];
        this.crudResponseValidationModel = {};
        $(`form#${this.formId}`).removeClass("was-validated");
      },
    },
  },
  components: {
    DesignItem,
    HiddenFields,
    ValidationMessageList,
    ValidationMessageListInModal,
  },
  methods: {
    validateEvent(allFields) {
      var form = $(`form#${this.formId}`),
        model = this.$root.modelCreate(`form#${this.formId}`);

      this.crudResponseValidationModel = {
        showValidations: false,
        msg: "",
        message: "",
        errors: [],
        modalErrors: [],
        warnings: [],
        modalWarnings: [],
        informations: [],
        modalInformations: [],
        businessRuleErrors: [],
      };

      if (!form[0].checkValidity() || !this.$root.forceFormValidation(form)) {
        form.addClass("was-validated");

        this.$root.elementScrollToTop($(`#${this.modalId}`));
        this.crudResponseValidationModel.showValidations = true;
        this.crudResponseValidationModel.errors = this.$root.formValidation(
          form,
          model.values,
          allFields
        );

        return false;
      }

      return true;
    },
    saveEvent(buttonName) {
      var form = $(`form#${this.formId}`),
        allFields = this.pageData.fields.find(
          (f) => f.isMainObject
        ).fieldModels;
      if (!this.validateEvent(allFields)) return;

      var button = $(`.${buttonName}`),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses),
        self = this;

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.crudResponseValidationModel = {
        showValidations: false,
        msg: "",
        message: "",
        errors: [],
        modalErrors: [],
        warnings: [],
        modalWarnings: [],
        informations: [],
        modalInformations: [],
        businessRuleErrors: [],
      };

      var model = this.$root.modelCreate(`form#${this.formId}`);
      if (model.values.length > 0) {
        model.values = model.values
          .filter(function (f) {
            return (
              (String.isNullOrWhiteSpace(f.value) &&
                !String.isNullOrWhiteSpace(f.oldValue)) ||
              !String.isNullOrWhiteSpace(f.value)
            );
          })
          .map(function (m) {
            return {
              key: m.key,
              value: m.value,
            };
          });
      }
      this.model = model;

      this.$appAxios
        .post("/rws-SetController-New", this.model)
        .then((response) => {
          var result = response.data;

          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          form.removeClass("was-validated");

          if (result.isOk) {
            createToast(
              this.$t(
                "BaseModelFields.SuccessMessage",
                {},
                { locale: this.$store.state.activeLang }
              ),
              {
                showIcon: true,
                position: "top-right",
                type: "success",
                transition: "zoom",
              }
            );

            this.$emit("addEvent", this.model, result);
            hideModal(document.getElementById(this.modalId));
          } else {
            this.$root.elementScrollToTop($(`#${this.modalId}`));

            //todo: warnings and info messages
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);

            this.crudResponseValidationModel = {
              msg: result.msg,
              message: result.message,
              errors: this.$root.getValidationMessages(
                result.validations,
                false,
                1,
                allFields
              ),
              modalErrors: this.$root.getValidationMessages(
                result.validations,
                true,
                1,
                allFields
              ),
              warnings: this.$root.getValidationMessages(
                result.validations,
                false,
                2,
                allFields
              ),
              modalWarnings: this.$root.getValidationMessages(
                result.validations,
                true,
                2,
                allFields
              ),
              informations: this.$root.getValidationMessages(
                result.validations,
                false,
                3,
                allFields
              ),
              modalInformations: this.$root.getValidationMessages(
                result.validations,
                true,
                3,
                allFields
              ),
              businessRuleErrors:
                result.businessRuleResponse.businessRuleResponses,
            };

            //unknown error
            if (
              String.isNullOrWhiteSpace(this.crudResponseValidationModel.msg) &&
              String.isNullOrWhiteSpace(
                this.crudResponseValidationModel.message
              ) &&
              this.crudResponseValidationModel.errors.length == 0 &&
              this.crudResponseValidationModel.warnings.length == 0 &&
              this.crudResponseValidationModel.informations.length == 0 &&
              this.crudResponseValidationModel.modalErrors.length == 0 &&
              this.crudResponseValidationModel.modalWarnings.length == 0 &&
              this.crudResponseValidationModel.modalInformations.length == 0
            ) {
              this.crudResponseValidationModel.msg = this.$t(
                "BaseModelFields.AnUnknownErrorHasOccurred",
                {},
                { locale: this.$store.state.activeLang }
              );
            }

            this.crudResponseValidationModel.showValidations =
              !String.isNullOrWhiteSpace(
                this.crudResponseValidationModel.msg
              ) ||
              !String.isNullOrWhiteSpace(
                this.crudResponseValidationModel.message
              ) ||
              this.crudResponseValidationModel.errors.length > 0 ||
              this.crudResponseValidationModel.warnings.length > 0 ||
              this.crudResponseValidationModel.informations.length > 0;

            createToast(
              this.$t(
                "BaseModelFields.ErrorMessage",
                {},
                { locale: this.$store.state.activeLang }
              ),
              {
                showIcon: true,
                position: "top-right",
                type: "danger",
                transition: "zoom",
              }
            );
          }

          var modalShow =
            this.crudResponseValidationModel.businessRuleErrors.length > 0 ||
            this.crudResponseValidationModel.modalErrors.length > 0 ||
            this.crudResponseValidationModel.modalWarnings.length > 0 ||
            this.crudResponseValidationModel.modalInformations.length > 0;
          if (modalShow) {
            showModal(document.getElementById("modalValidationMessage"));
          }
        })
        .catch(function (error) {
          // alert(error);
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          self.formError.push(error);
        });
    },
    updateEvent(buttonName) {
      var form = $(`form#${this.formId}`),
        allFields = this.pageData.fields.find(
          (f) => f.isMainObject
        ).fieldModels;
      if (!this.validateEvent(allFields)) return;

      var button = $(`.${buttonName}`),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses),
        self = this;

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.crudResponseValidationModel = {
        showValidations: false,
        msg: "",
        message: "",
        errors: [],
        modalErrors: [],
        warnings: [],
        modalWarnings: [],
        informations: [],
        modalInformations: [],
        businessRuleErrors: [],
      };

      this.formError = [];
      this.model = this.$root.modelCreate(`form#${this.formId}`);
      this.$appAxios
        .post("/rws-SetController-Edit", this.model)
        .then((response) => {
          var result = response.data;

          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          form.removeClass("was-validated");

          if (result.isOk) {
            createToast(
              this.$t(
                "BaseModelFields.SuccessMessage",
                {},
                { locale: this.$store.state.activeLang }
              ),
              {
                showIcon: true,
                position: "top-right",
                type: "success",
                transition: "zoom",
              }
            );

            this.$emit("updateEvent", this.model, result);
            hideModal(document.getElementById(this.modalId));
          } else {
            this.$root.elementScrollToTop($(`#${this.modalId}`));

            //todo: warnings and info messages
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);

            this.crudResponseValidationModel = {
              msg: result.msg,
              message: result.message,
              errors: this.$root.getValidationMessages(
                result.validations,
                false,
                1,
                allFields
              ),
              modalErrors: this.$root.getValidationMessages(
                result.validations,
                true,
                1,
                allFields
              ),
              warnings: this.$root.getValidationMessages(
                result.validations,
                false,
                2,
                allFields
              ),
              modalWarnings: this.$root.getValidationMessages(
                result.validations,
                true,
                2,
                allFields
              ),
              informations: this.$root.getValidationMessages(
                result.validations,
                false,
                3,
                allFields
              ),
              modalInformations: this.$root.getValidationMessages(
                result.validations,
                true,
                3,
                allFields
              ),
              businessRuleErrors:
                result.businessRuleResponse.businessRuleResponses,
            };

            //unknown error
            if (
              String.isNullOrWhiteSpace(this.crudResponseValidationModel.msg) &&
              String.isNullOrWhiteSpace(
                this.crudResponseValidationModel.message
              ) &&
              this.crudResponseValidationModel.errors.length == 0 &&
              this.crudResponseValidationModel.warnings.length == 0 &&
              this.crudResponseValidationModel.informations.length == 0 &&
              this.crudResponseValidationModel.modalErrors.length == 0 &&
              this.crudResponseValidationModel.modalWarnings.length == 0 &&
              this.crudResponseValidationModel.modalInformations.length == 0
            ) {
              this.crudResponseValidationModel.msg = this.$t(
                "BaseModelFields.AnUnknownErrorHasOccurred",
                {},
                { locale: this.$store.state.activeLang }
              );
            }

            this.crudResponseValidationModel.showValidations =
              !String.isNullOrWhiteSpace(
                this.crudResponseValidationModel.msg
              ) ||
              !String.isNullOrWhiteSpace(
                this.crudResponseValidationModel.message
              ) ||
              this.crudResponseValidationModel.errors.length > 0 ||
              this.crudResponseValidationModel.warnings.length > 0 ||
              this.crudResponseValidationModel.informations.length > 0;

            createToast(
              this.$t(
                "BaseModelFields.ErrorMessage",
                {},
                { locale: this.$store.state.activeLang }
              ),
              {
                showIcon: true,
                position: "top-right",
                type: "danger",
                transition: "zoom",
              }
            );
          }

          var modalShow =
            this.crudResponseValidationModel.businessRuleErrors.length > 0 ||
            this.crudResponseValidationModel.modalErrors.length > 0 ||
            this.crudResponseValidationModel.modalWarnings.length > 0 ||
            this.crudResponseValidationModel.modalInformations.length > 0;
          if (modalShow) {
            showModal(document.getElementById("modalValidationMessage"));
          }
        })
        .catch(function (error) {
          // alert(error);
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          self.formError.push(error);
        });
    },
  },
};
</script>
