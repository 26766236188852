<template>
  <!-- <AlertBox type="warning" :messages="errors" /> -->
  <FullCalendar ref="fullCalendar" class="mt-3" :options="calendarOptions">
    <template v-slot:eventContent="arg">
      <div class="fc-daygrid-event-dot" v-if="arg.timeText"></div>
      <div class="fc-event-time" v-if="arg.timeText">{{ arg.timeText }}</div>
      <div class="fc-event-title">{{ arg.event.title }}</div>
    </template>
  </FullCalendar>
  <EventModal
    :calendarInfo="calendar"
    :pageData="pageData"
    :designedItems="designedItems"
    :crudType="crudType"
    @addEvent="addEvent"
    @updateEvent="updateEvent"
  />
  <DragAndDropOrResizeEventErrorModal
    :errors="crudResponseValidationModel.errors"
    :warnings="crudResponseValidationModel.warning"
    :informations="crudResponseValidationModel.informations"
    :businessRuleErrors="crudResponseValidationModel.businessRuleErrors"
  />
</template>
<script>
import { createToast } from "mosha-vue-toastify";
import dxDateBox from "devextreme/ui/date_box";
import { showModal, removeTooltips } from "@/core/helpers/dom";

import { Tooltip } from "bootstrap";
import $ from "jquery";

//component(s)
import FullCalendar from "@fullcalendar/vue3";
import EventModal from "@/components/full-calendar/EventModal.vue";
import DragAndDropOrResizeEventErrorModal from "@/components/full-calendar/DragAndDropOrResizeEventErrorModal.vue";

//full calendar used plugins
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import timelinePlugin from "@fullcalendar/timeline";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import resourceDayGridPlugin from "@fullcalendar/resource-daygrid";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import bootstrap5Plugin from "@fullcalendar/bootstrap5";
import listGridPlugin from "@fullcalendar/list";
import allLocales from "@fullcalendar/core/locales-all";
export default {
  components: {
    FullCalendar,
    EventModal,
    DragAndDropOrResizeEventErrorModal,
  },
  props: ["self", "events", "resources", "calendar", "fields"],
  data() {
    return {
      selectInfo: null,
      calendarApi: null,
      currentEventInfo: null,
      pageData: {},
      crudType: "new",
      designedItems: [],
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          timelinePlugin,
          resourceTimelinePlugin,
          resourceDayGridPlugin,
          resourceTimeGridPlugin,
          bootstrap5Plugin,
          listGridPlugin,
        ],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: this.calendar.views.split(this.$systemSeparator).join(","),
        },
        themeSystem: "bootstrap5",
        initialView: this.calendar.defaultView,
        initialEvents: this.events,
        editable: this.calendar.isEditableOnCalendar,
        selectable: this.calendar.isInsertOnCalendar,
        selectMirror: this.calendar.isInsertOnCalendar,
        dayMaxEvents: true,
        weekends: true,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        navLinks: true,
        weekNumbers: false,
        slotEventOverlap: true,
        height: window.innerHeight,
        resources: this.resources,
        resourceAreaHeaderContent: this.calendar.timelineFieldName,
        aspectRatio: 1.63,
        firstDay: 1, //Sunday=0, Monday=1, Tuesday=2 etc.
        locale: this.self.$store.state.activeLang,
        locales: allLocales,
        schedulerLicenseKey: process.env.VUE_APP_FULLCALENDAR_LICENSE_KEY,
        slotMinTime: this.calendar.minTime,
        slotMaxTime: this.calendar.maxTime,
        resourceAreaWidth: "25%",
        eventDrop: this.eventDrop,
        eventResize: this.eventResize,
        slotLabelFormat: {
          hour: "numeric",
          minute: "2-digit",
          omitZeroMinute: false,
          meridiem: "short",
        },
        titleFormat: {
          year: "numeric",
          month: "long",
          day: "numeric",
          weekday: "long",
        },
        initialDate: new Date().toISOString().replace(/T.*$/, ""),
        buttonText: {
          today: this.self.$t(
            "Components.FullCalendar.Today",
            {},
            { locale: this.self.$store.state.activeLang }
          ),
          month: this.self.$t(
            "Components.FullCalendar.Month",
            {},
            { locale: this.self.$store.state.activeLang }
          ),
          week: this.self.$t(
            "Components.FullCalendar.Week",
            {},
            { locale: this.self.$store.state.activeLang }
          ),
          day: this.self.$t(
            "Components.FullCalendar.Day",
            {},
            { locale: this.self.$store.state.activeLang }
          ),
          list: this.self.$t(
            "Components.FullCalendar.List",
            {},
            { locale: this.self.$store.state.activeLang }
          ),
        },
        eventDidMount: function (info) {
          new Tooltip(info.el, {
            title: info.event._def.title,
            placement: "top",
            trigger: "hover",
            container: "body",
          });
        },
      },
      currentEvents: [],
      errors: [],
      crudResponseValidationModel: {},
    };
  },
  methods: {
    eventDrop(args) {
      this.errors = [];
      removeTooltips();
      var start = args.event.start,
        end = args.event.end,
        recordPublicId = args.event.id,
        resourceId = args.event._def.resourceIds[0];

      var model = {
        customObjectPublicId: this.calendar.customObjectPublicId,
        customObjectKey: this.$route.params.key,
        pageLayoutId: this.calendar.pageDesignerPublicId,
        recordPublicId: recordPublicId,
        values: [
          {
            key: this.$root.getFieldFormulaName(
              this.fields,
              this.calendar.startDateFieldPublicId
            ),
            value: this.$root.dateTimeFormat(start),
          },
          {
            key: this.$root.getFieldFormulaName(
              this.fields,
              this.calendar.endDateFieldPublicId
            ),
            value: String.isNullOrWhiteSpace(end)
              ? ""
              : this.$root.dateTimeFormat(end),
          },
        ],
      };

      if (
        !String.isNullOrWhiteSpace(resourceId) &&
        !String.isNullOrWhiteSpace(this.calendar.timelineFieldPublicId)
      )
        model.values.push({
          key: this.$root.getFieldFormulaName(
            this.fields,
            this.calendar.timelineFieldPublicId
          ),
          value: resourceId,
        });

      this.$appAxios
        .post("/rws-SetController-Edit", model)
        .then((response) => {
          var result = response.data;

          if (result.isOk) {
            createToast(
              this.$t(
                "BaseModelFields.SuccessMessage",
                {},
                { locale: this.$store.state.activeLang }
              ),
              {
                showIcon: true,
                position: "top-right",
                type: "success",
                transition: "zoom",
              }
            );
          } else {
            //revert old position
            args.revert();

            this.crudResponseValidationModel = {
              msg: result.msg,
              message: result.message,
              errors: this.$root.getValidationMessages(
                result.validations,
                false,
                1,
                this.fields.map((m) => m.fieldModel),
                true
              ),
              warnings: this.$root.getValidationMessages(
                result.validations,
                false,
                2,
                this.fields.map((m) => m.fieldModel),
                true
              ),
              informations: this.$root.getValidationMessages(
                result.validations,
                false,
                3,
                this.fields.map((m) => m.fieldModel),
                true
              ),
              businessRuleErrors:
                result.businessRuleResponse.businessRuleResponses,
            };

            var modalShow =
              this.crudResponseValidationModel.businessRuleErrors.length > 0 ||
              this.crudResponseValidationModel.errors.length > 0 ||
              this.crudResponseValidationModel.warnings.length > 0 ||
              this.crudResponseValidationModel.informations.length > 0;
            if (modalShow) {
              showModal(
                document.getElementById("dragAndDropOrResizeEventModal")
              );
            }

            var toastMessage = this.$t(
              "BaseModelFields.ErrorMessage",
              {},
              { locale: this.$store.state.activeLang }
            );
            if (
              !String.isNullOrWhiteSpace(this.crudResponseValidationModel.msg)
            ) {
              toastMessage = this.crudResponseValidationModel.msg;
            }

            createToast(toastMessage, {
              showIcon: true,
              position: "top-right",
              type: "danger",
              transition: "zoom",
            });
          }
        })
        .catch(function (error) {
          //TODO:Error
          // alert(error);
        });
    },
    eventResize(args) {
      this.errors = [];
      removeTooltips();
      var start = args.event.start,
        end = args.event.end,
        recordPublicId = args.event.id;

      var model = {
        customObjectPublicId: this.calendar.customObjectPublicId,
        customObjectKey: this.$route.params.key,
        pageLayoutId: this.calendar.pageDesignerPublicId,
        recordPublicId: recordPublicId,
        values: [
          {
            key: this.$root.getFieldFormulaName(
              this.fields,
              this.calendar.startDateFieldPublicId
            ),
            value: this.$root.dateTimeFormat(start),
          },
          {
            key: this.$root.getFieldFormulaName(
              this.fields,
              this.calendar.endDateFieldPublicId
            ),
            value: this.$root.dateTimeFormat(end),
          },
        ],
      };

      this.$appAxios
        .post("/rws-SetController-Edit", model)
        .then((response) => {
          var result = response.data;

          if (result.isOk) {
            createToast(
              this.$t(
                "BaseModelFields.SuccessMessage",
                {},
                { locale: this.$store.state.activeLang }
              ),
              {
                showIcon: true,
                position: "top-right",
                type: "success",
                transition: "zoom",
              }
            );
          } else {
            //revert old position
            args.revert();

            this.crudResponseValidationModel = {
              msg: result.msg,
              message: result.message,
              errors: this.$root.getValidationMessages(
                result.validations,
                false,
                1,
                this.fields.map((m) => m.fieldModel),
                true
              ),
              warnings: this.$root.getValidationMessages(
                result.validations,
                false,
                2,
                this.fields.map((m) => m.fieldModel),
                true
              ),
              informations: this.$root.getValidationMessages(
                result.validations,
                false,
                3,
                this.fields.map((m) => m.fieldModel),
                true
              ),
              businessRuleErrors:
                result.businessRuleResponse.businessRuleResponses,
            };

            var modalShow =
              this.crudResponseValidationModel.businessRuleErrors.length > 0 ||
              this.crudResponseValidationModel.errors.length > 0 ||
              this.crudResponseValidationModel.warnings.length > 0 ||
              this.crudResponseValidationModel.informations.length > 0;
            if (modalShow) {
              showModal(
                document.getElementById("dragAndDropOrResizeEventModal")
              );
            }

            var toastMessage = this.$t(
              "BaseModelFields.ErrorMessage",
              {},
              { locale: this.$store.state.activeLang }
            );
            if (
              !String.isNullOrWhiteSpace(this.crudResponseValidationModel.msg)
            ) {
              toastMessage = this.crudResponseValidationModel.msg;
            }

            createToast(toastMessage, {
              showIcon: true,
              position: "top-right",
              type: "danger",
              transition: "zoom",
            });
          }
        })
        .catch(function (error) {
          //TODO:Error
          // alert(error);
        });
    },
    handleDateSelect(selectInfo) {
      if (this.calendar.isInsertOnCalendar) {
        this.pageData = {};
        this.designedItems = [];

        this.selectInfo = selectInfo;
        this.calendarApi = selectInfo.view.calendar;
        this.calendarApi.unselect(); // clear date selection

        this.getCalendarNewPage(
          this.self.$route.params.key,
          this.calendar.pageDesignerPublicId,
          selectInfo
        );
      }
    },
    addEvent(model, result) {
      var eventTitles = this.calendar.titleFieldPublicIds.split(
          this.$systemSeparator
        ),
        self = this,
        eventTitleValues = [],
        shortFormatInfo = this.$root.getDateTimeFormat("short"),
        format = String.format(
          "{0} {1}",
          shortFormatInfo.calendar.toUpperCase(),
          shortFormatInfo.time
        );

      var startDate = model.values.find(
        (f) =>
          f.key ===
          self.$root.getFieldFormulaName(
            this.fields,
            this.calendar.startDateFieldPublicId
          )
      );
      if (String.isNullOrWhiteSpace(startDate)) {
        startDate = this.selectInfo.startStr;
      } else {
        startDate = startDate.value;
      }

      var endDate = model.values.find(
        (f) =>
          f.key ===
          self.$root.getFieldFormulaName(
            this.fields,
            this.calendar.endDateFieldPublicId
          )
      );
      if (String.isNullOrWhiteSpace(endDate)) {
        endDate = this.selectInfo.endStr;
      } else {
        endDate = endDate.value;
      }

      var resourceField = model.values.find(
        (f) =>
          f.key ==
          self.$root.getFieldFormulaName(
            this.fields,
            this.calendar.timelineFieldPublicId
          )
      );
      if (String.isNullOrWhiteSpace(resourceField)) {
        resourceField = !String.isNullOrWhiteSpace(this.selectInfo.resource)
          ? this.selectInfo.resource._resource.id
          : "";
      } else {
        resourceField = resourceField.value;
      }

      eventTitles.forEach((element) => {
        eventTitleValues.push(
          self.$root.getPageItemFormValue(
            $(String.format("[data-publicid='{0}']", element))
          )
        );
      });

      var title = eventTitleValues
        .map((u) => u.text)
        .filter((f) => !String.isNullOrWhiteSpace(f))
        .join(", ");
      this.calendarApi.addEvent({
        id: result.recordPublicId,
        resourceId: resourceField,
        title: title,
        start: self.$root.$moment(startDate, format).format(),
        end: self.$root.$moment(endDate, format).format(),
        allDay: String.isNullOrWhiteSpace(endDate),
        url: "",
      });
    },
    updateEvent(model, result) {
      if (!String.isNullOrWhiteSpace(this.currentEventInfo)) {
        var eventTitles = this.calendar.titleFieldPublicIds.split(
            this.$systemSeparator
          ),
          self = this,
          eventTitleValues = [],
          shortFormatInfo = this.$root.getDateTimeFormat("short"),
          format = String.format(
            "{0} {1}",
            shortFormatInfo.calendar.toUpperCase(),
            shortFormatInfo.time
          );

        var resourceField = model.values.find(
          (f) =>
            f.key ==
            this.$root.getFieldFormulaName(
              this.fields,
              this.calendar.timelineFieldPublicId
            )
        );
        if (
          !String.isNullOrWhiteSpace(resourceField) &&
          resourceField.value !== resourceField.oldValue
        ) {
          this.currentEventInfo.event.setResources([resourceField.value]);
        }

        var startDate = model.values.find(
          (f) =>
            f.key ===
            self.$root.getFieldFormulaName(
              this.fields,
              this.calendar.startDateFieldPublicId
            )
        );
        if (
          !String.isNullOrWhiteSpace(startDate) &&
          startDate.value !== startDate.oldValue
        ) {
          this.currentEventInfo.event.setStart(
            self.$root.$moment(startDate.value, format).format()
          );
        }

        var endDate = model.values.find(
          (f) =>
            f.key ===
            self.$root.getFieldFormulaName(
              this.fields,
              this.calendar.endDateFieldPublicId
            )
        );
        if (
          !String.isNullOrWhiteSpace(endDate) &&
          endDate.value !== endDate.oldValue
        ) {
          this.currentEventInfo.event.setEnd(
            self.$root.$moment(endDate.value, format).format()
          );
        }

        eventTitles.forEach((element) => {
          eventTitleValues.push(
            self.$root.getPageItemFormValue(
              $(String.format("[data-publicid='{0}']", element))
            )
          );
        });

        var title = eventTitleValues
          .map((u) => u.text)
          .filter((f) => !String.isNullOrWhiteSpace(f))
          .join(", ");
        this.currentEventInfo.event.setProp("title", title);
      }
    },
    handleEventClick(selectInfo) {
      this.currentEventInfo = selectInfo;
      if (this.calendar.isEditableOnCalendar) {
        this.pageData = {};
        this.designedItems = [];
        this.getCalendarEditPage(
          this.self.$route.params.key,
          this.calendar.pageDesignerPublicId,
          selectInfo,
          selectInfo.event.id
        );
      }
    },
    handleEvents(events) {
      this.currentEvents = events;
    },
    getCalendarEditPage(key, pageLayoutId, selectInfo, recordPublicId) {
      this.crudType = "edit";

      showModal(document.getElementById("fullCalendarEventModal"));

      var editPageRequestUrl = `rws-SetController-EditPage?key=${key}&id=${recordPublicId}`;
      if (!String.isNullOrWhiteSpace(pageLayoutId)) {
        editPageRequestUrl += `&pageLayoutId=${pageLayoutId}`;
      }

      this.self.$appAxios
        .get(editPageRequestUrl)
        .then((response) => {
          var result = response.data;
          if (result.isOk) {
            this.pageData = result;
            this.$parent.pageData = this.pageData;

            if (
              String.isNullOrWhiteSpace(this.pageData.layout) ||
              String.isNullOrWhiteSpace(this.pageData.layout.layout)
            ) {
              createToast(
                this.$t(
                  "BaseModelFields.PageLayoutEmpty",
                  {},
                  { locale: this.$store.state.activeLang }
                ),
                {
                  showIcon: true,
                  position: "top-right",
                  type: "success",
                  transition: "zoom",
                }
              );
              return;
            }

            if (this.pageData.layout.layout) {
              this.designedItems = JSON.parse(this.pageData.layout.layout);
            }
          } else {
            if (!String.isNullOrWhiteSpace(result.msg)) {
              this.errors.push(result.msg);
            }

            if (!String.isNullOrWhiteSpace(result.message)) {
              this.errors.push(result.message);
            }

            if (!String.isNullOrWhiteSpace(result.errors)) {
              result.errors.forEach((element) => {
                this.errors.push(
                  String.format("{0}: {1}", element.fieldName, element.message)
                );
              });
            }

            createToast(
              this.errors.length > 0
                ? this.errors[0]
                : this.$t(
                    "BaseModelFields.ErrorMessage",
                    {},
                    { locale: this.$store.state.activeLang }
                  ),
              {
                showIcon: true,
                position: "top-right",
                type: "danger",
                transition: "zoom",
              }
            );
          }
        })
        .catch(function (error) {
          //TODO:Error
          // alert(error);
        });
    },
    getCalendarNewPage(key, pageLayoutId, selectInfo) {
      this.crudType = "new";

      showModal(document.getElementById("fullCalendarEventModal"));

      var newPageRequestUrl = `rws-SetController-NewPage?key=${key}`;
      if (!String.isNullOrWhiteSpace(pageLayoutId)) {
        newPageRequestUrl += `&pageLayoutId=${pageLayoutId}`;
      }

      this.self.$appAxios
        .get(newPageRequestUrl)
        .then((response) => {
          var result = response.data;
          if (result.isOk) {
            this.pageData = result;

            var resource = selectInfo.resource;
            if (
              !String.isNullOrWhiteSpace(resource) &&
              !String.isNullOrWhiteSpace(this.calendar.timelineFieldPublicId)
            ) {
              var currentField = this.fields.find(
                  (f) => f.publicId === this.calendar.timelineFieldPublicId
                ),
                formulaName = currentField.fieldModel.formulaName;

              resource = resource._resource;

              var value = this.pageData.values.find(
                (f) => f.key === formulaName
              );
              if (String.isNullOrWhiteSpace(value)) {
                this.pageData.values.push({
                  key: formulaName,
                  value: `${resource.id}${this.$selectSeparator}${resource.title}`,
                });
              } else {
                value.value = `${resource.id}${this.$selectSeparator}${resource.title}`;
              }
            }

            //set start date
            var startDate = selectInfo.start,
              endDate = selectInfo.end;
            if (
              !String.isNullOrWhiteSpace(startDate) &&
              !String.isNullOrWhiteSpace(this.calendar.startDateFieldPublicId)
            ) {
              currentField = this.fields.find(
                (f) => f.publicId === this.calendar.startDateFieldPublicId
              );

              formulaName = currentField.fieldModel.formulaName;

              value = this.pageData.values.find((f) => f.key === formulaName);
              if (String.isNullOrWhiteSpace(value)) {
                this.pageData.values.push({
                  key: formulaName,
                  value: this.self.$root.dateTimeFormat(startDate),
                });
              } else {
                value.value = this.self.$root.dateTimeFormat(startDate);
              }
            }

            if (
              !String.isNullOrWhiteSpace(endDate) &&
              !String.isNullOrWhiteSpace(this.calendar.endDateFieldPublicId)
            ) {
              currentField = this.fields.find(
                (f) => f.publicId === this.calendar.endDateFieldPublicId
              );

              formulaName = currentField.fieldModel.formulaName;

              value = this.pageData.values.find((f) => f.key === formulaName);
              if (String.isNullOrWhiteSpace(value)) {
                this.pageData.values.push({
                  key: formulaName,
                  value: this.self.$root.dateTimeFormat(endDate),
                });
              } else {
                value.value = this.self.$root.dateTimeFormat(endDate);
              }
            }

            this.$parent.pageData = this.pageData;

            if (
              String.isNullOrWhiteSpace(this.pageData.layout) ||
              String.isNullOrWhiteSpace(this.pageData.layout.layout)
            ) {
              createToast(
                this.$t(
                  "BaseModelFields.PageLayoutEmpty",
                  {},
                  { locale: this.$store.state.activeLang }
                ),
                {
                  showIcon: true,
                  position: "top-right",
                  type: "success",
                  transition: "zoom",
                }
              );
              return;
            }

            if (this.pageData.layout.layout) {
              this.designedItems = JSON.parse(this.pageData.layout.layout);
            }
          } else {
            if (!String.isNullOrWhiteSpace(result.msg)) {
              this.errors.push(result.msg);
            }

            if (!String.isNullOrWhiteSpace(result.message)) {
              this.errors.push(result.message);
            }

            if (!String.isNullOrWhiteSpace(result.errors)) {
              result.errors.forEach((element) => {
                this.errors.push(
                  String.format("{0}: {1}", element.fieldName, element.message)
                );
              });
            }

            createToast(
              this.errors.length > 0
                ? this.errors[0]
                : this.$t(
                    "BaseModelFields.ErrorMessage",
                    {},
                    { locale: this.$store.state.activeLang }
                  ),
              {
                showIcon: true,
                position: "top-right",
                type: "danger",
                transition: "zoom",
              }
            );
          }
        })
        .catch(function (error) {
          //TODO:Error
          // alert(error);
        });
    },
  },
  mounted() {
    $(".fc-header-toolbar .fc-toolbar-chunk:first").append(
      $("<div/>", {
        id: "gotoDate",
        class: "me-2",
      })
    );

    var shortFormatInfo = this.self.$root.getDateTimeFormat("short"),
      format = String.format(
        "{0} {1}",
        shortFormatInfo.calendar.toUpperCase(),
        shortFormatInfo.time
      ),
      momentPlugin = this.self.$moment,
      fcSelf = this;

    new dxDateBox("#gotoDate", {
      opened: false,
      type: "date",
      visible: true,
      value: new Date(),
      useMaskBehavior: true,
      openOnFieldClick: true,
      onValueChanged: (e) => {
        var date = momentPlugin(e.value, format).format();
        fcSelf.$refs.fullCalendar.calendar.gotoDate(date);
      },
    });
  },
};
</script>
